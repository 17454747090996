









































































































import Vue from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import { IDataTable } from '@/types/table'
import ColorSwatches from '@/mixins/colorSwatches'
import { IMachineDetailQuery } from '@/types/machines'
import { IDateTimePicker } from '@/types/dateTimePicker'
import { formatCurrencyWithSpaces, formatDate } from '@/utils/helpers'
import { dateColorSwatch } from '@/utils/colorSwatches'
import moment from 'moment-timezone'
import { DanaQuery, DanaResponse, IMachine } from '@/types/dana'
import DanaApi from '@/services/danaApi'

export default Vue.extend({
	name: 'MachineDetail',
	components: { DataTable },
	mixins: [ColorSwatches],
	data() {
		return {
			tableProps: {
				headers: [
					{
						text: 'Datetime',
						value: 'datetime',
						type: 'dateTime',
					},
					{
						text: 'In',
						value: 'IN',
						type: 'currency',
						append: process.env.VUE_APP_CURRENCY,
						sum: true,
						sumValue: 'IN',
					},
					{
						text: 'Out',
						value: 'OUT',
						type: 'currency',
						append: process.env.VUE_APP_CURRENCY,
						sum: true,
						sumValue: 'OUT',
					},
				],
				filters: [
					{
						source: 'dateTime',
						type: 'DateTimePicker',
						attributes: {} as IDateTimePicker,
						//TODO need debugging not working properly with more than one default value
						defaultValue: [],
						alwaysOn: true,
					},
					{
						source: 'order',
						type: 'SelectInput',
						label: 'Order',
						choices: [
							{
								value: 'IN_desc',
								text: 'IN descending',
							},
							{
								value: 'IN_asc',
								text: 'IN ascending',
							},
							{
								value: 'OUT_desc',
								text: 'OUT descending',
							},
							{
								value: 'OUT_asc',
								text: 'OUT ascending',
							},
						],
						attributes: {
							smallChips: true,
						},
					},
				],
			} as IDataTable,
			machineDetail: {} as DanaResponse.IBookkeepings,
			machineDetailLoading: false,
			summaryLoading: false,
			netto: 0,
			rtp: 0,
			payout: 0,
			machineInfo: {} as IMachine,
			machineInfoLoading: false,
			currency: process.env.VUE_APP_CURRENCY as string,
		}
	},
	computed: {
		lastOnlineSwatch(): string {
			const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

			const success = moment(new Date(new Date(date).setMinutes(date.getMinutes() - 10)).toISOString())
				.format()
				.substring(0, 19)

			const warning = moment(new Date(new Date(date).setHours(date.getHours() - 1)).toISOString())
				.format()
				.substring(0, 19)

			if (this.machineInfo.last_online) {
				return dateColorSwatch(this.machineInfo.last_online, success, warning, true)
			} else {
				return ''
			}
		},
		lastSyncSwatch(): string {
			const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

			const success = moment(new Date(new Date(date).setMinutes(date.getMinutes() - 10)).toISOString())
				.format()
				.substring(0, 19)

			const warning = moment(new Date(new Date(date).setHours(date.getHours() - 1)).toISOString())
				.format()
				.substring(0, 19)

			if (this.machineInfo.last_sync) {
				return dateColorSwatch(this.machineInfo.last_sync, success, warning, true)
			} else {
				return ''
			}
		},
	},
	methods: {
		formatCurrency: formatCurrencyWithSpaces,
		formatDate: formatDate,
		dateColorSwatch: dateColorSwatch,
		setDefaultDate(): void {
			let sinceDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				return moment(new Date(new Date(date).setMonth(date.getMonth() - 1)).toISOString())
					.format()
					.substring(0, 10)
			}

			let toDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				return moment(date.toISOString()).format().substring(0, 10)
			}

			this.tableProps.filters[0].defaultValue.push(sinceDate())
			this.tableProps.filters[0].defaultValue.push(toDate())
		},
		async getMachineDetail(id: number, options: IMachineDetailQuery) {
			this.machineDetailLoading = true

			let sort = []
			let sortOrder = []

			if (options.filters.order) {
				const sortIn = options.filters.order.split('_')[0]
				sort.push(sortIn)
				const sortInOrder = options.filters.order.split('_')[1]
				sortOrder.push(sortInOrder)
			}

			this.machineDetailLoading = true

			try {
				this.machineDetail = await new DanaApi().get<DanaResponse.IBookkeepings, DanaQuery.IBookkeepings>(
					'/stats/bookkeepings',
					{
						machine_id: id,
						limit: options.pagination.itemsPerPage,
						offset: (options.pagination.page - 1) * options.pagination.itemsPerPage,
						//TODO possible Error with array access
						since_date: options.filters.dateTime[0] ?? null,
						to_date: options.filters.dateTime[1] ?? null,
						sort_columns: sort,
						sort_orders: sortOrder,
					},
				)
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.machineDetailLoading = false
		},
		async getSummary(id: number, options: IMachineDetailQuery): Promise<void> {
			this.summaryLoading = true

			try {
				const response = await new DanaApi().get<DanaResponse.ISummaryGranulated, DanaQuery.ISummaryGranulated>(
					'/stats/summary',
					{
						machine_id: id,
						since_date: options.filters.dateTime[0] ?? null,
						to_date: options.filters.dateTime[1] ?? null,
					},
				)

				if (response.bookkeepings.length > 0) {
					this.netto = response.bookkeepings[0].netto / 100
					if (response.bookkeepings[0].payroll) {
						this.payout = Math.round((response.bookkeepings[0].payroll + Number.EPSILON) * 100) / 100
					}
				}

				if (response.spins.length > 0) {
					if (response.spins[0].rtp) {
						this.rtp = Math.round((response.spins[0].rtp + Number.EPSILON) * 100) / 100
					}
				}
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.summaryLoading = false
		},
		async getMachine(id: number) {
			this.machineInfoLoading = true

			try {
				const response = await new DanaApi().get<DanaResponse.IMachines, DanaQuery.IMachines>('/info/machines', {
					machine_id: id,
				})

				if (response.machines.length > 0) {
					this.machineInfo = response.machines[0]
				}
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.machineInfoLoading = false
		},
		fetchMachineDetail(event: IMachineDetailQuery) {
			this.getSummary(parseInt(this.$route.params.id, 10), event)
			this.getMachineDetail(parseInt(this.$route.params.id, 10), event)
		},
	},
	created() {
		this.getMachine(parseInt(this.$route.params.id, 10))
		this.setDefaultDate()
	},
})
